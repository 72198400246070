import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Leaves() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [id, setId] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [leaveSubject, setLeaveSubject] = useState("");

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
    if (event.currentTarget.value == "VIEW LEAVES") {
      setCurrentLeaves(users[userIndex]["leave_requests"]);
    } else {
      setCurrentLeaves(JSON.parse(state.auth.user.leave_requests));
    }
  };

  const [selectedPage, setSelectedPage] = useState(
    state.auth.isLoading
      ? "VIEW LEAVES"
      : !state.auth.user.is_staff || !state.auth.user.is_manager
      ? "VIEW LEAVES"
      : params.get("tab")
      ? params.get("tab")
      : "APPLY"
  );

  const colorList = [
    "#e5f5e0",
    state.auth.theme == "dark"
      ? themeStyleDark.secondaryButtonColor
      : themeStyle.secondaryButtonColor,
    "#fee6ce",
  ];
  const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
  const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

  const [isLoading, setIsLoading] = useState(true);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [leaveId, setLeaveId] = useState("");
  const [email, setEmail] = useState("");
  const [leaveDate, setLeaveDate] = useState("");
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [currentLeaves, setCurrentLeaves] = useState([]);

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function refresh() {
    axios
      .get(API_URL + "api/auth/user", {
        headers: {
          Authorization: state.auth.token,
        },
      })
      .then((res) => {
        setCurrentLeaves(JSON.parse(res.data.leave_requests));
        dispatch({
          type: "USER_LOADED",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUTH_ERROR",
        });
      });
  }

  function submitLeave() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["leave_subject"] = leaveSubject;
    body["date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["upload_date"] = moment().format("DD-MM-YYYY");
    axios
      .post(API_URL + "add_leave_request", body, config)
      .then(() => {
        setIsSubmitting(false);
        setOpen2(false);
        setLeaveSubject("");
        createAlert("SUCCESS", "Success.", "Leave request submitted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function deleteLeave() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["leave_id"] = id;

    axios
      .post(API_URL + "delete_leave_request", body, config)
      .then(() => {
        setIsSubmitting(false);
        refresh();
        createAlert("SUCCESS", "Success.", "Leave request deleted.");
        setOpen3(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      if (state.auth.user.is_staff) {
        setUsers(res.data);
        setCurrentLeaves(res.data[userIndex]["leave_requests"]);
        setIsLoading(false);
      } else {
        var tempUsers = res.data;
        var list = [];
        for (var i = 0; i < tempUsers.length; i++) {
          if (tempUsers[i]["reports_to"] == state.auth.user.email) {
            list.push(tempUsers[i]);
          }
        }
        setUsers(list);
        if (list.length > 0) {
          setCurrentLeaves(res.data[userIndex]["leave_requests"]);
        }
        setIsLoading(false);
      }
    });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setCurrentLeaves(users[e.target.value]["leave_requests"]);
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (
        state.auth.user.is_staff ||
        state.auth.user.is_manager ||
        state.auth.user.is_hr
      ) {
        getData();
      } else {
        setIsLoading(false);
        setCurrentLeaves(JSON.parse(state.auth.user.leave_requests));
      }
    }
  }, []);

  if (state.auth.refresh) {
    if (
      state.auth.user.is_staff ||
      state.auth.user.is_manager ||
      state.auth.user.is_hr
    ) {
      getData();
    }
    refresh();

    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function approveLeave(approval) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["leave_request_id"] = leaveId;
    body["approval_date"] = moment().format("DD-MM-YYYY");
    body["email"] = users[userIndex]["email"];
    body["admin"] = state.auth.user.email;
    body["approval"] = approval ? "APPROVED" : "REJECTED";

    body = JSON.stringify(body);

    axios
      .post(API_URL + "approve_leave_request", body, config)
      .then((res) => {
        getData();
        handleClose();
        createAlert(
          "SUCCESS",
          "Success.",
          approval ? "Leave APPROVED" : "Leave REJECTED"
        );
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  function getPageContent() {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      if (selectedPage == "APPLY") {
        return (
          <div style={{ width: "100%" }}>
            <Grid container spacing={5}>
              <Grid item md={8}>
                {currentLeaves.length == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No Leave requests.</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {currentLeaves.map((leave, index) => (
                  <Box
                    p={2}
                    style={{
                      height: "80px",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      border:
                        leave["status"] == "PENDING"
                          ? "1.5px solid" + state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor
                          : "none",
                      backgroundColor:
                        leave["status"] == "PENDING"
                          ? "white"
                          : leave["status"] == "APPROVED"
                          ? colorList[0]
                          : colorList[2],
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItemsL: "center",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <div
                          style={{
                            margin: "-25px 0px 0px -3px",
                          }}
                        >
                          <h3
                            style={{
                              lineHeight: "20px",
                              color:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            {leave["subject"]}
                          </h3>
                        </div>
                        <div
                          style={{
                            margin: "-15px 0px 0px -3px",
                            fontSize: "14px",
                          }}
                        >
                          {moment(leave["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {leave["status"] == "PENDING" ? (
                            <ButtonBase
                              style={{
                                cursor: "pointer",
                                marginLeft: "-10px",
                                display: "flex",
                                backgroundColor: "#ff3333",
                                borderRadius: "6px",
                                padding: "3px",
                                alignItems: "center",
                                justifyContent: "space-between",
                                position: "relative",
                                bottom: "62px",
                                height: "25px",
                                width: "25px",
                                right: "-105px",
                              }}
                              onClick={() => {
                                setId(leave["id"]);
                                setOpen3(true);
                              }}
                            >
                              <DeleteIcon
                                style={{ color: "white", fontSize: "18px" }}
                              />
                            </ButtonBase>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          marginTop:
                            leave["status"] == "PENDING" ? "-12px" : "-5px",
                          marginRight: "-15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            textAlign="center"
                            p={1}
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "60px",
                              backgroundColor:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                              color: "white",
                            }}
                          >
                            {leave["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : leave["status"] == "APPROVED" ? (
                              <CheckIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : (
                              <CloseIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            )}
                          </Box>
                          <b
                            style={{
                              marginTop: "5px",
                              color:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "lighter",
                              }}
                            >
                              {leave["status"]}
                            </span>
                          </b>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
              </Grid>
              <Grid item md={4}>
                <div
                  style={{
                    marginTop: "-25px",
                    marginBottom: "-15px",
                    fontWeight: "bold",
                    fontSize: "24px",
                  }}
                >
                  <h3>Apply for Leave</h3>
                </div>
                <span
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontSize: "16px",
                  }}
                >
                  Choose a date
                </span>
                <br></br>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{
                      height: "40px",
                      width: "100%",
                      marginTop: "5px",
                      marginBottom: "20px",
                    }}
                    inputFormat="dd-MM-yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        style={{
                          width: "100%",
                          marginTop: "2px",
                          marginBottom: "5px",
                          height: "40px",
                        }}
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                <br></br>
                <div
                  style={{
                    margin: "15px 0px 5px 0px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontSize: "16px",
                  }}
                >
                  Subject
                </div>
                <TextField
                  value={leaveSubject}
                  style={{ marginTop: "2px" }}
                  onChange={(e) => setLeaveSubject(e.target.value)}
                  fullWidth
                  placeholder="Reason"
                  variant="outlined"
                  size="small"
                />
                <br></br>
                <br></br>

                <Button
                  onClick={() => {
                    setOpen2(true);
                  }}
                  style={{
                    width: "100%",
                    fontSize: "15px",
                    height: "35px",
                    marginTop: "-1px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                  variant="contained"
                  component="span"
                >
                  <p>Submit Leave Request</p>
                </Button>

                {isSubmitting ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return (
          <Fragment>
            <Grid container spacing={1.5}>
              <Grid item md={8}>
                <Grid container spacing={1.5}>
                  {currentLeaves.length == 0 ? (
                    <div
                      style={{
                        marginTop: "200px",
                        height: "100px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Fab
                          size="large"
                          style={{
                            border: "3px solid #aaa",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          aria-label="add"
                        >
                          <PriorityHighIcon
                            style={{
                              color: "grey",
                            }}
                          />
                        </Fab>
                        <br />
                        <h4 style={{ color: "grey" }}>
                          No leave requests for this user.
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {currentLeaves.map((leave, index) => (
                    <Grid item md={3}>
                      <Box
                        style={{
                          minHeight: "100%",
                          borderRadius: "6px",
                          border: "1.3px solid #d7ecff",
                          backgroundColor: themeStyle.primaryCardColor,
                          boxShadow: "none",
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            margin: "-5px 0px 0px 0px",
                            fontSize: "18px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonTextColor
                                : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          {leave["name"]}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          {leave["subject"]}
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                        >
                          {leave["class"]}
                        </div>
                        <div style={{ marginTop: "-5px" }}>
                          {moment(leave["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <div style={{ marginTop: "-5px", color: "grey" }}>
                          {moment(leave["upload_date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <div style={{ margin: "0px 0px 0px 0px" }}>
                          {leave["description"]}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "-5px",
                            marginRight: "-5px",
                          }}
                        >
                          <Fab
                            size="small"
                            style={{
                              transform: "scale(0.8)",
                              boxShadow: "none",
                              backgroundColor:
                                leave["status"] == "PENDING"
                                  ? state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                            onClick={() => {
                              if (leave["status"] == "PENDING") {
                                setLeaveId(leave["id"]);
                                setEmail(leave["email"]);
                                setLeaveDate(leave["date"]);
                                setOpen(true);
                              }
                            }}
                            aria-label="add"
                          >
                            {leave["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            ) : (
                              <Fragment>
                                {leave["status"] == "APPROVED" ? (
                                  <CheckIcon
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                ) : (
                                  <CloseIcon
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                )}{" "}
                              </Fragment>
                            )}
                          </Fab>
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item md={4}>
                <span
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontSize: "14px",
                  }}
                >
                  Select User
                </span>
                <Select
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  variant={"outlined"}
                  fullWidth
                  value={userIndex}
                  onChange={(e) => handleUserChange(e)}
                >
                  {getUsersList()}
                </Select>
              </Grid>
            </Grid>
          </Fragment>
        );
      }
    } else {
      return (
        <div style={{ width: "100%" }}>
          <Grid container spacing={5}>
            <Grid item md={8}>
              {JSON.parse(state.auth.user.leave_requests).length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No Leave requests.</h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentLeaves.map((leave, index) => (
                <Box
                  p={2}
                  style={{
                    height: "80px",
                    marginBottom: "15px",
                    borderRadius: "15px",
                    border:
                      leave["status"] == "PENDING"
                        ? "1.5px solid" + state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor
                        : "none",
                    backgroundColor:
                      leave["status"] == "PENDING"
                        ? "white"
                        : leave["status"] == "APPROVED"
                        ? colorList[0]
                        : colorList[2],
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItemsL: "center",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div
                        style={{
                          margin: "-25px 0px 0px -3px",
                        }}
                      >
                        <h3
                          style={{
                            lineHeight: "20px",
                            color:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          {leave["subject"]}
                        </h3>
                      </div>
                      <div
                        style={{
                          margin: "-15px 0px 0px -3px",
                          fontSize: "14px",
                        }}
                      >
                        {moment(leave["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {leave["status"] == "PENDING" ? (
                          <ButtonBase
                            style={{
                              cursor: "pointer",
                              marginLeft: "-10px",
                              display: "flex",
                              backgroundColor: "#ff3333",
                              borderRadius: "6px",
                              padding: "3px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              position: "relative",
                              bottom: "62px",
                              height: "25px",
                              width: "25px",
                              right: "-105px",
                            }}
                            onClick={() => {
                              setId(leave["id"]);
                              setOpen3(true);
                            }}
                          >
                            <DeleteIcon
                              style={{ color: "white", fontSize: "18px" }}
                            />
                          </ButtonBase>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop:
                          leave["status"] == "PENDING" ? "-12px" : "-5px",
                        marginRight: "-15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          textAlign="center"
                          p={1}
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "60px",
                            backgroundColor:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                            color: "white",
                          }}
                        >
                          {leave["status"] == "PENDING" ? (
                            <PriorityHighIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : leave["status"] == "APPROVED" ? (
                            <CheckIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : (
                            <CloseIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          )}
                        </Box>
                        <b
                          style={{
                            marginTop: "5px",
                            color:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "lighter",
                            }}
                          >
                            {leave["status"]}
                          </span>
                        </b>
                      </div>
                    </div>
                  </div>
                </Box>
              ))}
            </Grid>
            <Grid item md={4}>
              <div
                style={{
                  marginTop: "-25px",
                  marginBottom: "-15px",
                  fontWeight: "bold",
                  fontSize: "24px",
                }}
              >
                <h3>Apply for Leave</h3>
              </div>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Choose a date
              </span>
              <br></br>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{
                    height: "40px",
                    width: "100%",
                    marginTop: "5px",
                    marginBottom: "20px",
                  }}
                  inputFormat="dd-MM-yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        width: "100%",
                        marginTop: "2px",
                        marginBottom: "5px",
                        height: "40px",
                      }}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <br></br>
              <div
                style={{
                  margin: "15px 0px 5px 0px",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Subject
              </div>
              <TextField
                value={leaveSubject}
                style={{ marginTop: "2px" }}
                onChange={(e) => setLeaveSubject(e.target.value)}
                fullWidth
                placeholder="Reason"
                variant="outlined"
                size="small"
              />
              <br></br>
              <br></br>

              <Button
                onClick={() => {
                  setOpen2(true);
                }}
                style={{
                  width: "100%",
                  fontSize: "15px",
                  height: "35px",
                  marginTop: "-1px",
                  backgroundColor: themeStyle.primaryColor,
                }}
                variant="contained"
                component="span"
              >
                <p>Submit Leave Request</p>
              </Button>

              {isSubmitting ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  state.auth.user.is_staff || state.auth.user.is_manager ? (
                    <Grid container spacing={1.5} justifyContent={"flex-end"}>
                      <Grid item md={4}>
                        {selectedPage == "APPLY" ? (
                          <Button
                            fullWidth
                            value={"APPLY"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              APPLY
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value={"APPLY"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonColor
                                  : themeStyle.secondaryButtonColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.secondaryButtonTextColor
                                    : themeStyle.secondaryButtonTextColor,
                              }}
                            >
                              APPLY
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedPage == "VIEW LEAVES" ? (
                          <Button
                            fullWidth
                            value="VIEW LEAVES"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              VIEW LEAVES
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="VIEW LEAVES"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonColor
                                  : themeStyle.secondaryButtonColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.secondaryButtonTextColor
                                    : themeStyle.secondaryButtonTextColor,
                              }}
                            >
                              VIEW LEAVES
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Fragment />
                  )
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Approve this leave request?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => approveLeave(true)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => approveLeave(false)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Submit leave request?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen2(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => submitLeave()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open3}
                onClose={() => setOpen3(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open3}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen3(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Delete this leave request?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen3(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => deleteLeave()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor: themeStyle.backgroundColor,
                  height: "100vh",
                  width: "100vw",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Leaves;
