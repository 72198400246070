import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import moment from "moment";
import Fab from "@mui/material/Fab";
import { themeStyle, themeStyleDark } from "../components/Components";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Feedback() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState("GOOD");

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "FEEDBACK"
  );

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      if (state.auth.user.is_staff) {
        setUsers(res.data);
        setFeedback(res.data[0]["feedback"]);
        setIsLoading(false);
      } else {
        var tempUsers = res.data;
        var list = [];
        for (var i = 0; i < tempUsers.length; i++) {
          if (tempUsers[i]["reports_to"] == state.auth.user.email) {
            list.push(tempUsers[i]);
          }
        }
        setUsers(list);
        if (list.length > 0) {
          setFeedback(res.data[0]["feedback"]);
        }
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh) {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setFeedback(users[e.target.value]["feedback"]);
  }

  const [fadeIn, setFadeIn] = useState(false);

  const [q1, setQ1] = useState(3);
  const [q2, setQ2] = useState(3);
  const [q3, setQ3] = useState(3);
  const [q4, setQ4] = useState(3);
  const [q5, setQ5] = useState(3);
  const [q6, setQ6] = useState(3);
  const [q7, setQ7] = useState(3);
  const [q8, setQ8] = useState(3);
  const [q9, setQ9] = useState(3);
  const [q10, setQ10] = useState(3);
  const [q11, setQ11] = useState(3);
  const [q12, setQ12] = useState(3);
  const [q13, setQ13] = useState(3);

  const [qd1, setQD1] = useState("");
  const [qd2, setQD2] = useState("");
  const [qd3, setQD3] = useState("");
  const [qd4, setQD4] = useState("");
  const [qd5, setQD5] = useState("");
  const [qd6, setQD6] = useState("");
  const [qd7, setQD7] = useState("");
  const [qd8, setQD8] = useState("");
  const [qd9, setQD9] = useState("");
  const [qd10, setQD10] = useState("");
  const [qd11, setQD11] = useState("");
  const [qd12, setQD12] = useState("");
  const [qd13, setQD13] = useState("");

  const [q, setQ] = useState(0);

  var questions = [
    "",
    "Verbal Communication - How were the candidate´s communication skills during the interview? i.e. body language, answers to questions.",
    "Candidate Enthusiasm - How much interest did the candidate show in the position and the company?",
    "Knowledge of Company - Did the candidate research the company prior to the interview?",
    "Teambuilding/Interpersonal Skills - Did the candidate demonstrate, through their answers, good teambuilding/interpersonal skills?",
    "Initiative - Did the candidate demonstrate, through their answers, a high degree of initiative?",
    "Time Management - Did the candidate demonstrate, through their answers, good time management skills?",
    "Customer Service - Did the candidate demonstrate, through their answers, a high level of customer service skills/abilities?",
    "Salary Expectations - What were the candidate´s salary expectations? Were they within the range for the position?",
    "Overall Impression and Recommendation - Final comments and recommendations for proceeding with the candidate.",
    "Educational Background - Does the candidate have the appropriate educational qualifications or training for this position?",
    "Prior Work Experience - Has the candidate acquired similar skills or qualifications through past work experiences?",
    "Technical Qualifications/Experience - Does the candidate have the technical skills necessary for this position?",
    "Do you have any additional feedback for this candidate?",
  ];

  function submitFeedback() {
    setIsLoading(true);
    var a = {
      q1: { r: q1, f: qd1 },
      q2: { r: q2, f: qd2 },
      q3: { r: q3, f: qd3 },
      q4: { r: q4, f: qd4 },
      q5: { r: q5, f: qd5 },
      q6: { r: q6, f: qd6 },
      q7: { r: q7, f: qd7 },
      q8: { r: q8, f: qd8 },
      q9: { r: q9, f: qd9 },
      q10: { r: q10, f: qd10 },
      q11: { r: q11, f: qd11 },
      q12: { r: q12, f: qd12 },
      q13: { r: q13, f: qd13 },
    };
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["feedback"] = a;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "submit_feedback", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Feedback submitted");
        setIsLoading(false);
        setSelectedPage("FEEDBACK");
      })
      .catch((err) => {
        // createAlert("ERROR", "Error", err.response.data);
      });
  }

  function previousQuestion() {
    if (q > 1) {
      setFadeIn(false);
      setTimeout(() => {
        setQ(q - 1);
        setFadeIn(true);
      }, 1000);
    }
  }

  function nextQuestion() {
    if (q < 13) {
      setFadeIn(false);
      setTimeout(() => {
        setQ(q + 1);
        setFadeIn(true);
      }, 1000);
    } else {
      submitFeedback();
    }
  }

  function getTextInput(q) {
    if (q == 1) {
      return (
        <TextField
          value={qd1}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD1(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 2) {
      return (
        <TextField
          value={qd2}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD2(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 3) {
      return (
        <TextField
          value={qd3}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD3(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 4) {
      return (
        <TextField
          value={qd4}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD4(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 5) {
      return (
        <TextField
          value={qd5}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD5(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 6) {
      return (
        <TextField
          value={qd6}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD6(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 7) {
      return (
        <TextField
          value={qd7}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD7(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 8) {
      return (
        <TextField
          value={qd8}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD8(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 9) {
      return (
        <TextField
          value={qd9}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD9(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 10) {
      return (
        <TextField
          value={qd10}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD10(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 11) {
      return (
        <TextField
          value={qd11}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD11(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 12) {
      return (
        <TextField
          value={qd12}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD12(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 13) {
      return (
        <TextField
          value={qd13}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD13(e.target.value)}
          fullWidth
          placeholder="Comments"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    }
  }

  function getText1(q) {
    if (q == 1) {
      return <></>;
    } else if (q == 2) {
      return (
        <Box style={{ marginTop: "-20px", marginRight: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            NOT
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              USEFUL
            </h4>
          </div>
        </Box>
      );
    } else if (q == 13) {
      return <></>;
    } else {
      return (
        <Box style={{ marginTop: "-20px", marginRight: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            NOT
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              EASY
            </h4>
          </div>
        </Box>
      );
    }
  }

  function getText2(q) {
    if (q == 1) {
      return <Fragment></Fragment>;
    } else if (q == 2) {
      return (
        <Box style={{ marginTop: "-20px", marginLeft: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            VERY
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              USEFUL
            </h4>
          </div>
        </Box>
      );
    } else if (q == 13) {
      return <Fragment></Fragment>;
    } else {
      return (
        <Box style={{ marginTop: "-20px", marginLeft: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            VERY
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              EASY
            </h4>
          </div>
        </Box>
      );
    }
  }

  function getButtonColor(q, n) {
    if (q == 1) {
      if (q1 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 2) {
      if (q2 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 3) {
      if (q3 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 4) {
      if (q4 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 5) {
      if (q5 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 6) {
      if (q6 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 7) {
      if (q7 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 8) {
      if (q8 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 9) {
      if (q9 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 10) {
      if (q10 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 11) {
      if (q11 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 12) {
      if (q12 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 13) {
      if (q13 == n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    }
  }

  function getTextColor(q, n) {
    if (q == 1) {
      if (q1 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 2) {
      if (q2 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 3) {
      if (q3 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 4) {
      if (q4 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 5) {
      if (q5 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 6) {
      if (q6 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 7) {
      if (q7 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 8) {
      if (q8 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 9) {
      if (q9 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 10) {
      if (q10 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 11) {
      if (q11 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 12) {
      if (q12 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    } else if (q == 13) {
      if (q13 != n) {
        return themeStyle.primaryColor;
      } else {
        return "white";
      }
    }
  }

  var colorIndex = 0;

  function getContent() {
    if (selectedPage == "SUBMIT") {
      return (
        <Fragment>
          <Sidebar />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "-18px",
                width: "40%",
              }}
            >
              <div style={{}}>
                <h1>Feedback For {users[userIndex]["full_name"]}</h1>
                <div style={{ marginTop: "-10px" }}>
                  <h4>
                    This is a candidate evaluation form for{" "}
                    {users[userIndex]["full_name"]}. This will be visible to the
                    Admin after submission.
                  </h4>
                </div>
                <Fade in={fadeIn}>
                  <div>
                    <h2
                      style={{
                        textAlign: "center",
                        color: "black",
                        marginTop: 56,
                      }}
                    >
                      {questions[q]}
                    </h2>
                    {q != 13 ? (
                      <Box
                        style={{
                          marginTop: 50,
                          height: 50,
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: 50,
                        }}
                      >
                        {getText1(q)}
                        <Button
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 5,
                            backgroundColor: getButtonColor(q, 1),
                          }}
                          onClick={() => {
                            if (q == 1) {
                              setQ1(1);
                            } else if (q == 2) {
                              setQ2(1);
                            } else if (q == 3) {
                              setQ3(1);
                            } else if (q == 4) {
                              setQ4(1);
                            } else if (q == 5) {
                              setQ5(1);
                            } else if (q == 6) {
                              setQ6(1);
                            } else if (q == 7) {
                              setQ7(1);
                            } else if (q == 8) {
                              setQ8(1);
                            } else if (q == 9) {
                              setQ9(1);
                            } else if (q == 10) {
                              setQ10(1);
                            } else if (q == 11) {
                              setQ11(1);
                            } else if (q == 12) {
                              setQ12(1);
                            } else if (q == 13) {
                              setQ13(1);
                            }
                          }}
                        >
                          <h4
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              color: getTextColor(q, 1),
                              fontWeight: "bold",
                            }}
                          >
                            1
                          </h4>
                        </Button>
                        <Button
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 5,
                            backgroundColor: getButtonColor(q, 2),
                          }}
                          onClick={() => {
                            if (q == 1) {
                              setQ1(2);
                            } else if (q == 2) {
                              setQ2(2);
                            } else if (q == 3) {
                              setQ3(2);
                            } else if (q == 4) {
                              setQ4(2);
                            } else if (q == 5) {
                              setQ5(2);
                            } else if (q == 6) {
                              setQ6(2);
                            } else if (q == 7) {
                              setQ7(2);
                            } else if (q == 8) {
                              setQ8(2);
                            } else if (q == 9) {
                              setQ9(2);
                            } else if (q == 10) {
                              setQ10(2);
                            } else if (q == 11) {
                              setQ11(2);
                            } else if (q == 12) {
                              setQ12(2);
                            } else if (q == 13) {
                              setQ13(2);
                            }
                          }}
                        >
                          <h4
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              color: getTextColor(q, 2),
                              fontWeight: "bold",
                            }}
                          >
                            2
                          </h4>
                        </Button>
                        <Button
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 5,
                            backgroundColor: getButtonColor(q, 3),
                          }}
                          onClick={() => {
                            if (q == 1) {
                              setQ1(3);
                            } else if (q == 2) {
                              setQ2(3);
                            } else if (q == 3) {
                              setQ3(3);
                            } else if (q == 4) {
                              setQ4(3);
                            } else if (q == 5) {
                              setQ5(3);
                            } else if (q == 6) {
                              setQ6(3);
                            } else if (q == 7) {
                              setQ7(3);
                            } else if (q == 8) {
                              setQ8(3);
                            } else if (q == 9) {
                              setQ9(3);
                            } else if (q == 10) {
                              setQ10(3);
                            } else if (q == 11) {
                              setQ11(3);
                            } else if (q == 12) {
                              setQ12(3);
                            } else if (q == 13) {
                              setQ13(3);
                            }
                          }}
                        >
                          <h4
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              color: getTextColor(q, 3),
                              fontWeight: "bold",
                            }}
                          >
                            3
                          </h4>
                        </Button>
                        <Button
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 5,
                            backgroundColor: getButtonColor(q, 4),
                          }}
                          onClick={() => {
                            if (q == 1) {
                              setQ1(4);
                            } else if (q == 2) {
                              setQ2(4);
                            } else if (q == 3) {
                              setQ3(4);
                            } else if (q == 4) {
                              setQ4(4);
                            } else if (q == 5) {
                              setQ5(4);
                            } else if (q == 6) {
                              setQ6(4);
                            } else if (q == 7) {
                              setQ7(4);
                            } else if (q == 8) {
                              setQ8(4);
                            } else if (q == 9) {
                              setQ9(4);
                            } else if (q == 10) {
                              setQ10(4);
                            } else if (q == 11) {
                              setQ11(4);
                            } else if (q == 12) {
                              setQ12(4);
                            } else if (q == 13) {
                              setQ13(4);
                            }
                          }}
                        >
                          <h4
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              color: getTextColor(q, 4),
                              fontWeight: "bold",
                            }}
                          >
                            4
                          </h4>
                        </Button>
                        <Button
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 5,
                            backgroundColor: getButtonColor(q, 5),
                          }}
                          onClick={() => {
                            if (q == 1) {
                              setQ1(5);
                            } else if (q == 2) {
                              setQ2(5);
                            } else if (q == 3) {
                              setQ3(5);
                            } else if (q == 4) {
                              setQ4(5);
                            } else if (q == 5) {
                              setQ5(5);
                            } else if (q == 6) {
                              setQ6(5);
                            } else if (q == 7) {
                              setQ7(5);
                            } else if (q == 8) {
                              setQ8(5);
                            } else if (q == 9) {
                              setQ9(5);
                            } else if (q == 10) {
                              setQ10(5);
                            } else if (q == 11) {
                              setQ11(5);
                            } else if (q == 12) {
                              setQ12(5);
                            } else if (q == 13) {
                              setQ13(5);
                            }
                          }}
                        >
                          <h4
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              color: getTextColor(q, 5),
                              fontWeight: "bold",
                            }}
                          >
                            5
                          </h4>
                        </Button>
                        {getText2(q)}
                      </Box>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    <Box
                      style={{
                        width: "100%",
                      }}
                    >
                      {getTextInput(q)}
                    </Box>
                  </div>
                </Fade>
                <Box
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 125,
                  }}
                >
                  <Button
                    onClick={() => {
                      previousQuestion();
                    }}
                    style={{
                      width: 140,
                      height: 40,
                      borderRadius: 5,
                      backgroundColor: themeStyle.primaryColor,
                    }}
                  >
                    <h4
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: 20,
                      }}
                    >
                      PREVIOUS
                    </h4>
                  </Button>
                  <div style={{ width: 50 }}></div>
                  <Button
                    onClick={() => {
                      nextQuestion();
                    }}
                    style={{
                      width: 140,
                      height: 40,
                      borderRadius: 5,
                      backgroundColor: themeStyle.primaryColor,
                    }}
                  >
                    <h4
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: 20,
                      }}
                    >
                      NEXT
                    </h4>
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    if (state.auth.user.is_staff) {
      return (
        <Fragment>
          <Grid container spacing={5}>
            <Grid item md={8}>
              {feedback.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No feedback submitted for this user.
                    </h4>
                  </div>
                </div>
              ) : (
                Object.keys(feedback).map((ques, index) => (
                  <Fragment>
                    {index == 0 ? (
                      ""
                    ) : (
                      <Box
                        p={2}
                        style={{
                          marginBottom: "15px",
                          borderRadius: "15px",
                          border: "2px solid #d7ecff",
                          backgroundColor: themeStyle.primaryCardColor,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "90%" }}>
                            <h3>{questions[index]}</h3>
                            <div style={{ marginTop: "-10px" }}>
                              <h2>
                                <b>Rating: {feedback[ques]["r"]}</b>
                              </h2>
                            </div>
                            <div style={{ marginTop: "-25px" }}>
                              <h4>Comments: {feedback[ques]["f"]}</h4>
                            </div>
                          </div>
                        </div>
                      </Box>
                    )}
                  </Fragment>
                ))
              )}
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "14px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => {
                  handleUserChange(e);
                }}
              >
                {getUsersList()}
              </Select>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item md={8}>
              {feedback.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No feedback submitted.</h4>
                  </div>
                </div>
              ) : (
                Object.keys(feedback).map((ques, index) => (
                  <Fragment>
                    {index == 0 ? (
                      ""
                    ) : (
                      <Box
                        p={2}
                        style={{
                          marginBottom: "15px",
                          borderRadius: "15px",
                          border: "2px solid #d7ecff",
                          backgroundColor: themeStyle.primaryCardColor,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "90%" }}>
                            <h3>{questions[index]}</h3>
                            <div style={{ marginTop: "-10px" }}>
                              <h2>
                                <b>Rating: {feedback[ques]["r"]}</b>
                              </h2>
                            </div>
                            <div style={{ marginTop: "-25px" }}>
                              <h4>Comments: {feedback[ques]["f"]}</h4>
                            </div>
                          </div>
                        </div>
                      </Box>
                    )}
                  </Fragment>
                ))
              )}
            </Grid>

            <Grid item md={4} style={{ marginTop: "-23px" }}>
              <h3>Update Resignation Button Visibility</h3>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "14px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => {
                  handleUserChange(e);
                }}
              >
                {getUsersList()}
              </Select>

              <br></br>
              <br></br>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    fontSize: "15px",
                    height: "35px",
                    marginTop: "-1px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                  onClick={() => {
                    setSelectedPage("SUBMIT");
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  <h4>SUBMIT FEEDBACK</h4>
                </Button>
                {isSubmitting ? (
                  <div
                    style={{
                      marginTop: "60px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <h3>Submitting...</h3>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (state.auth.user.is_staff || state.auth.user.is_manager) {
          if (isLoading) {
            return (
              <Fragment>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginTop: "46vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    padding: "100px 50px 90px 90px",
                    backgroundColor: themeStyle.backgroundColor,
                    minHeight: "100vh",
                    width: "100vw",
                  }}
                >
                  {getContent()}
                </div>
              </Fragment>
            );
          }
        } else {
          dispatch({
            type: "AUTH_ERROR",
          });
          return <Navigate to="/login" />;
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      dispatch({
        type: "AUTH_ERROR",
      });
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Feedback;
