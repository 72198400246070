import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function Profiles() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [ogUsers, setOGusers] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState({});
  const [selectedPage, setSelectedPage] = useState("PROFILES");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRegi, setSelectedRegi] = useState("MANAGER");
  const [clearText, setClearText] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={users[index]["email"]}>
        {users[index]["full_name"]}
      </MenuItem>
    ));
    return userss;
  }

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setUsers(ogUsers);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempUsers = [];

      for (var i = 0; i < ogUsers.length; i++) {
        console.log(ogUsers[i]);
        if (
          ogUsers[i]["full_name"].includes(text) ||
          ogUsers[i]["email"].includes(text)
        ) {
          tempUsers.push(ogUsers[i]);
        }
      }
      setCurrentPage(1);
      setUsers(tempUsers);
      setRandom(makeid(5));
    }
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogUsers);
    setSearch("");
    setCurrentPage(val);
    setUsers(tempUsers.splice((val - 1) * 25, 25));
  }

  function getData() {
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setOGusers(res.data);
      setProfile(res.data[0]);
      setUsers(res.data.splice(0, 25));
      setIsLoading(false);
    });
  }

  function reportsTo(report, email) {
    var body = {
      email: email,
      reports_to: report,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    axios.post(API_URL + "reports_to", body, config).then((res) => {
      setIsLoading(true);
      getData();
    });
  }

  if (state.auth.refresh) {
    if (state.auth.user.is_staff) {
      getData();
    }
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (state.auth.user.is_staff) {
        getData();
      }
    }
  }, []);

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const handleRegiSelect = (event) => {
    setSelectedRegi(event.currentTarget.value);
  };

  function registerNewUser() {
    var body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validEmailRegex)) {
      if (name.length !== 0) {
        body["email"] = email;
        body["full_name"] = name;
        body["password"] = password;

        body = JSON.stringify(body);

        axios
          .post(
            selectedRegi == "MANAGER"
              ? API_URL + "api/auth/register_manager"
              : selectedRegi == "HR"
              ? API_URL + "api/auth/register_hr"
              : API_URL + "api/auth/register_emp",
            body,
            config
          )
          .then((res) => {
            createAlert(
              "SUCCESS",
              "Registration complete.",
              selectedRegi + " registered successfully."
            );
          })
          .catch((err) => {
            createAlert(
              "ERROR",
              "Email already exists.",
              "Try again with another email."
            );
          });
      }
    } else {
      createAlert("ERROR", "Email invalid", "Please enter valid email address");
    }
    setEmail("");
    setName("");
    setPassword("");
    setIsLoading(false);
  }

  function getContent() {
    if (selectedPage == "REGISTER") {
      return (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryCardColor
                  : themeStyle.primaryCardColor,
              borderRadius: "15px",
              padding: "15px",
              boxShadow: "0px 0px 30px #ccc",
            }}
          >
            <h4>Register {selectedRegi}</h4>
            <Grid container spacing={1}>
              <Grid item md={4}>
                {selectedRegi == "MANAGER" ? (
                  <Button
                    fullWidth
                    value="MANAGER"
                    onClick={handleRegiSelect}
                    p={2}
                    style={{
                      marginTop: "-15px",
                      backgroundColor: themeStyle.primaryColor,
                      color: "white",
                      height: "25px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        letterSpacing: "1.5px",
                      }}
                    >
                      MANAGER
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="MANAGER"
                    onClick={handleRegiSelect}
                    p={2}
                    style={{
                      height: "25px",
                      marginTop: "-15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.secondaryButtonColor
                          : themeStyle.secondaryButtonColor,
                      color: "white",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.secondaryButtonTextColor
                            : themeStyle.secondaryButtonTextColor,
                      }}
                    >
                      MANAGER
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={4}>
                {selectedRegi == "HR" ? (
                  <Button
                    fullWidth
                    value="HR"
                    onClick={handleRegiSelect}
                    p={2}
                    style={{
                      marginTop: "-15px",
                      backgroundColor: themeStyle.primaryColor,
                      color: "white",
                      height: "25px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        letterSpacing: "1.5px",
                      }}
                    >
                      HR
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="HR"
                    onClick={handleRegiSelect}
                    p={2}
                    style={{
                      height: "25px",
                      marginTop: "-15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.secondaryButtonColor
                          : themeStyle.secondaryButtonColor,
                      color: "white",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.secondaryButtonTextColor
                            : themeStyle.secondaryButtonTextColor,
                      }}
                    >
                      HR
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={4}>
                {selectedRegi == "EMPLOYEE" ? (
                  <Button
                    fullWidth
                    value="EMPLOYEE"
                    onClick={handleRegiSelect}
                    p={2}
                    style={{
                      marginTop: "-15px",
                      backgroundColor: themeStyle.primaryColor,
                      color: "white",
                      height: "25px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        letterSpacing: "1.5px",
                      }}
                    >
                      EMPLOYEE
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EMPLOYEE"
                    onClick={handleRegiSelect}
                    p={2}
                    style={{
                      height: "25px",
                      marginTop: "-15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.secondaryButtonColor
                          : themeStyle.secondaryButtonColor,
                      color: "white",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.secondaryButtonTextColor
                            : themeStyle.secondaryButtonTextColor,
                      }}
                    >
                      EMPLOYEE
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>
            <TextField
              style={{
                backgroundColor: themeStyle.backgroundColor,
                borderRadius: "6px",
              }}
              variant="outlined"
              required
              fullWidth
              margin="normal"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.textColor
                    : themeStyle.textColor,
                borderRadius: "6px",
              }}
              variant="outlined"
              required
              fullWidth
              name="password"
              margin="normal"
              placeholder="Password"
              type={clearText ? "text" : "password"}
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    style={{ marginRight: "-8px", paddingLeft: "10px" }}
                    onClick={() => setClearText(!clearText)}
                  >
                    {clearText ? (
                      <VisibilityOffIcon
                        style={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                      />
                    ) : (
                      <VisibilityIcon
                        style={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                      />
                    )}
                  </IconButton>
                ),
              }}
            />
            <TextField
              style={{
                backgroundColor: themeStyle.backgroundColor,
                borderRadius: "6px",
              }}
              variant="outlined"
              required
              fullWidth
              margin="normal"
              name="fullname"
              autoComplete="fullname"
              autoFocus
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br></br> <br></br>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  fontSize: "15px",
                  marginTop: "-5px",
                  height: "35px",
                  backgroundColor: themeStyle.primaryColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  registerNewUser();
                }}
                variant="contained"
                color="primary"
              >
                <div>Register</div>
                <ChevronRightIcon
                  style={{
                    fontSize: "30px",
                    color: "white",
                    marginRight: "-10px",
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Grid container spacing={1.5} justifyContent={"flex-end"}>
              <Grid item md={12}>
                {Array.from(Array(Math.ceil(ogUsers.length / 25)).keys()).map(
                  (temp, index) => (
                    <div
                      style={{
                        cursor: "pointer",
                        margin: "3px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                        borderRadius: "30px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                      onClick={() => {
                        handleCurrentPage(index + 1);
                      }}
                    >
                      {index + 1}
                    </div>
                  )
                )}
                <div
                  style={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    borderRadius: "15px",
                    padding: "15px",
                    boxShadow: "0px 0px 30px #ccc",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{
                      justifyContent: "flex-start",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      paddingBottom: "20px",
                      borderBottom:
                        state.auth.theme == "dark"
                          ? "1px solid" + themeStyleDark.primaryColor
                          : "1px solid" + themeStyle.primaryColor,
                    }}
                  >
                    <Grid item md={1}>
                      No.
                    </Grid>
                    <Grid item md={3} style={{ textAlign: "center" }}>
                      Email
                    </Grid>
                    <Grid item md={2} style={{ textAlign: "center" }}>
                      Full Name
                    </Grid>
                    <Grid item md={3} style={{ textAlign: "center" }}>
                      Permissions
                    </Grid>
                    <Grid item md={3} style={{ textAlign: "center" }}>
                      Reports To
                    </Grid>
                  </Grid>
                  {users.map((user, index) => (
                    <Grid
                      onClick={() => setProfile(user)}
                      container
                      spacing={2}
                      style={{
                        color: "black",
                        padding: "15px",
                      }}
                    >
                      <Grid item md={1}>
                        {(currentPage - 1) * 25 + (index + 1)}
                      </Grid>
                      <Grid style={{ textAlign: "center" }} item md={3}>
                        {user["email"]}
                      </Grid>
                      <Grid style={{ textAlign: "center" }} item md={2}>
                        {user["full_name"]}
                      </Grid>
                      <Grid item md={3} style={{ textAlign: "center" }}>
                        {user["is_staff"] ? " - ADMIN - " : ""}
                        {user["is_hr"] ? "- HR -" : ""}
                        {user["is_manager"] ? "- MANAGER -" : ""}
                        {!user["is_staff"] &&
                        !user["is_hr"] &&
                        !user["is_manager"]
                          ? "- EMPLOYEE -"
                          : ""}
                      </Grid>
                      <Grid item md={3} style={{ textAlign: "center" }}>
                        <Select
                          style={{
                            marginTop: "2px",
                            height: "40px",
                            marginBottom: "5px",
                          }}
                          variant={"outlined"}
                          fullWidth
                          value={user["reports_to"]}
                          onChange={(e) => {
                            reportsTo(e.target.value, user["email"]);
                          }}
                        >
                          {getUsersList()}
                        </Select>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated && state.auth.user.is_staff) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: themeStyle.backgroundColor,
              }}
            >
              <CircularProgress style={{ color: themeStyle.primaryColor }} />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "PROFILES" ? (
                      <Button
                        fullWidth
                        value="PROFILES"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          marginTop: "-15px",
                          backgroundColor: themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            letterSpacing: "1.5px",
                          }}
                        >
                          PROFILES
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="PROFILES"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          height: "30px",
                          marginTop: "-15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonColor
                              : themeStyle.secondaryButtonColor,
                          color: "white",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonTextColor
                                : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          PROFILES
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {selectedPage == "REGISTER" ? (
                      <Button
                        fullWidth
                        value="REGISTER"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          marginTop: "-15px",
                          backgroundColor: themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            letterSpacing: "1.5px",
                          }}
                        >
                          REGISTER
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="REGISTER"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          height: "30px",
                          marginTop: "-15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonColor
                              : themeStyle.secondaryButtonColor,
                          color: "white",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonTextColor
                                : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          REGISTER
                        </h3>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
            <div
              style={{
                padding: "100px 50px 90px 115px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/dashboard" />;
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Profiles;
