import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Lottie from "lottie-react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Doughnut } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import { Bar } from "react-chartjs-2";
import welcome from "../img/welcome.json";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Legend,
  scales,
} from "chart.js";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { API_URL } from "../actions/auth";
import SchoolIcon from "@mui/icons-material/School";
import { themeStyle, themeStyleDark } from "../components/Components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Vaccines from "@mui/icons-material/Vaccines";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Filler,
  Legend,
  RadialLinearScale,
  ArcElement,
  zoomPlugin
);

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [leaves, setLeaves] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [news, setNews] = useState([]);
  const [taskMinutes, setTaskMinutes] = useState([]);
  const [taskLabels, setTaskLabels] = useState([]);
  const [random, setRandom] = useState("");

  function sendNotification() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body["send_to"] = selectedNoti;
    body["title"] = subject;
    body["message"] = message;

    axios.post(API_URL + "send_notification", body, config).then((res) => {
      createAlert("SUCCESS", "Sent.", "Notification sent!");
    });
  }

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "HOME"
  );

  function getData() {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      const config = {
        headers: {
          Authorization: state.auth.token,
        },
      };
      axios.get(API_URL + "get_dashboard", config).then((res) => {
        setTotalUsers(res.data["total_users"]);
        setLeaves(res.data["leaves"]);
        setIsLoading(false);
        axios
          .get(
            "https://api.thenewsapi.com/v1/news/top?api_token=xKsgQYgum0eKcwUftgGsRLouhzw3M5sqy2a88k3o&locale=us&limit=5",
            config
          )
          .then((res) => {
            setNews(res.data["data"]);
          });
      });
    } else {
      var tasks = JSON.parse(state.auth.user.tasks);
      var tm = [];
      var tl = [];
      for (var i = 0; i < tasks.length; i++) {
        if (tasks[i]["activity"].length == 2) {
          tm.push(
            moment(
              tasks[i]["activity"][0].split("at ")[1],
              "hh:mm A Do MMM, YYYY"
            ).diff(
              moment(
                tasks[i]["activity"][1].split("at ")[1],
                "hh:mm A Do MMM, YYYY"
              ),
              "minutes"
            )
          );

          tl.push(tasks[i]["subject"]);
        }
      }
      setTaskMinutes(tm);
      setTaskLabels(tl);
      setIsLoading(false);
      setRandom(makeid(4));
    }
  }

  const handleNotiSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh) {
    getData();

    dispatch(refreshData("dashboard"));
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Tasks",
      },
    },
  };

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent={"flex-end"}>
                    <Grid item md={4}>
                      {selectedPage == "HOME" ? (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonColor
                                : themeStyle.secondaryButtonColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonTextColor
                                  : themeStyle.secondaryButtonTextColor,
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    {/* <Grid item md={4}>
                    {selectedPage == "NOTIFICATIONS" ? (
                      <Button
                        fullWidth
                        value="NOTIFICATIONS"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          NOTIFICATIONS
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="NOTIFICATIONS"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: state.auth.theme == "dark"
                              ? themeStyleDark.secondaryButtonColor
                              : themeStyle.secondaryButtonColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: state.auth.theme == "dark"
                        ? themeStyleDark.secondaryButtonTextColor
                        : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          NOTIFICATIONS
                        </h3>
                      </Button>
                    )}
                  </Grid> */}
                  </Grid>
                }
              />
              {state.auth.user.is_staff || state.auth.user.is_manager ? (
                <div
                  style={{
                    padding: "100px 100px 90px 165px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.backgroundColor
                        : themeStyle.backgroundColor,
                    height: "100vh",
                    width: "100vw",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <CardActionArea
                        sx={[
                          {
                            height: "25vh",
                            borderRadius: "15px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryCardColor
                                : themeStyle.primaryCardColor,

                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                            transition:
                              "background-color 0.5s, border-color 0.5s",
                          },
                          (theme) => ({
                            "&& .MuiTouchRipple-child": {
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            },
                          }),
                        ]}
                        style={{
                          padding: "17px",
                          marginBottom: "10px",
                          boxShadow: "0px 0px 30px #ccc",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              flexDirection: "column",
                              width: "30%",
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              borderRadius: "15px",
                            }}
                          >
                            <PeopleAlt
                              style={{
                                padding: "10px",
                                color: "white",
                                border:
                                  state.auth.theme == "dark"
                                    ? "2px solid " + themeStyleDark.primaryColor
                                    : "2px solid " + themeStyle.primaryColor,
                                borderRadius: "10px",
                                textAlign: "center",
                                fontSize: "80px",
                                marginTop: "-10%",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "70%",
                              height: "100%",
                              paddingLeft: "25px",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "25px",
                                fontFamily: "Bahnschrift",
                              }}
                            >
                              Total Users
                            </div>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "60px",
                                fontWeight: "bold",
                                spacing: "5px",
                                fontFamily: "Kanit",
                              }}
                            >
                              {totalUsers}
                            </div>
                          </div>
                        </div>
                      </CardActionArea>
                    </Grid>
                    <Grid item md={4}>
                      <CardActionArea
                        sx={[
                          {
                            height: "25vh",
                            borderRadius: "15px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryCardColor
                                : themeStyle.primaryCardColor,

                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                            transition:
                              "background-color 0.5s, border-color 0.5s",
                          },
                          (theme) => ({
                            "&& .MuiTouchRipple-child": {
                              backgroundColor: "#08A045",
                            },
                          }),
                        ]}
                        style={{
                          padding: "17px",
                          marginBottom: "10px",
                          boxShadow: "0px 0px 30px #ccc",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              flexDirection: "column",
                              width: "30%",
                              backgroundColor: "#08A045",
                              borderRadius: "15px",
                            }}
                          >
                            <PersonIcon
                              style={{
                                padding: "10px",
                                color: "white",
                                border: "2px solid #08A045",
                                borderRadius: "10px",
                                textAlign: "center",
                                fontSize: "80px",
                                marginTop: "-10%",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "70%",
                              height: "100%",
                              paddingLeft: "25px",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "25px",
                                fontFamily: "Bahnschrift",
                              }}
                            >
                              Active Users
                            </div>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "60px",
                                fontWeight: "bold",
                                spacing: "5px",
                                fontFamily: "Kanit",
                              }}
                            >
                              {totalUsers}
                            </div>
                          </div>
                        </div>
                      </CardActionArea>
                    </Grid>
                    <Grid item md={4}>
                      <CardActionArea
                        sx={[
                          {
                            height: "25vh",
                            borderRadius: "15px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryCardColor
                                : themeStyle.primaryCardColor,

                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                            transition:
                              "background-color 0.5s, border-color 0.5s",
                          },
                          (theme) => ({
                            "&& .MuiTouchRipple-child": {
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            },
                          }),
                        ]}
                        style={{
                          padding: "17px",
                          marginBottom: "10px",
                          boxShadow: "0px 0px 30px #ccc",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              flexDirection: "column",
                              width: "30%",
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              borderRadius: "15px",
                            }}
                          >
                            <CurrencyRupeeIcon
                              style={{
                                padding: "10px",
                                color: "white",
                                border:
                                  state.auth.theme == "dark"
                                    ? "2px solid " + themeStyleDark.primaryColor
                                    : "2px solid " + themeStyle.primaryColor,
                                borderRadius: "10px",
                                textAlign: "center",
                                fontSize: "80px",
                                marginTop: "-10%",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "70%",
                              height: "100%",
                              paddingLeft: "25px",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "25px",
                                fontFamily: "Bahnschrift",
                              }}
                            >
                              Total Payslips
                            </div>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "60px",
                                fontWeight: "bold",
                                spacing: "5px",
                                fontFamily: "Kanit",
                              }}
                            >
                              {totalStudents}
                            </div>
                          </div>
                        </div>
                      </CardActionArea>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <div
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          marginTop: "6px",
                          padding: "15px",
                          boxShadow: "0px 0px 30px #ccc",
                          borderRadius: "15px",
                          height: "100%",
                        }}
                      >
                        {news.map((newss, index) => (
                          <CardActionArea
                            sx={[
                              {
                                borderRadius: "6px",
                                border: "1.5px solid #d7ecff",
                                transition:
                                  "background-color 0.5s, border-color 0.5s",
                              },
                              (theme) => ({
                                "&:hover": {
                                  backgroundColor: "#ebf5ff",
                                  borderColor:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.primaryColor
                                      : themeStyle.primaryColor,
                                },
                                ".MuiCardActionArea-focusHighlight": {
                                  background: "transparent",
                                },

                                "&& .MuiTouchRipple-child": {
                                  backgroundColor:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.secondaryButtonTextColor
                                      : themeStyle.secondaryButtonTextColor,
                                },
                              }),
                            ]}
                            style={{
                              padding: "7px",
                              width: "100%",
                              marginBottom: "10px",
                              backgroundColor: themeStyle.primaryCardColor,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-25px",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                  color: "blue",
                                }}
                              >
                                <h4>{newss["title"]}</h4>
                                <div
                                  style={{
                                    marginTop: "-25px",
                                    fontSize: "18px",
                                    color:
                                      state.auth.theme == "dark"
                                        ? themeStyleDark.primaryColor
                                        : themeStyle.primaryColor,
                                  }}
                                >
                                  {newss["description"]}
                                </div>
                                <div
                                  style={{
                                    color: "black",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {newss["snippet"]}
                                </div>
                              </div>
                            </div>
                          </CardActionArea>
                        ))}
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          padding: "20px",
                          borderRadius: "15px",
                          marginTop: "7px",
                          height: "100%",
                          boxShadow: "0px 0px 30px #ccc",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "5px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                            marginBottom: "35px",
                          }}
                        >
                          Send Notifications
                        </h2>
                        <Grid
                          container
                          spacing={1}
                          style={{ marginTop: "25px", marginBottom: "25px" }}
                        >
                          <Grid item md={4}>
                            {selectedNoti == "EVERYONE" ? (
                              <Button
                                fullWidth
                                value="EVERYONE"
                                onClick={handleNotiSelect}
                                p={2}
                                style={{
                                  backgroundColor:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.primaryColor
                                      : themeStyle.primaryColor,
                                  color: "white",
                                  height: "35px",
                                  marginTop: "-15px",
                                }}
                                borderRadius={6}
                              >
                                <h3
                                  style={{
                                    marginTop: "17px",
                                    fontSize: "15px",
                                    letterSpacing: "1.5px",
                                  }}
                                >
                                  EVERYONE
                                </h3>
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                value="EVERYONE"
                                onClick={handleNotiSelect}
                                p={2}
                                style={{
                                  height: "35px",
                                  backgroundColor: themeStyle.backgroundColor,
                                  color: "white",
                                  marginTop: "-15px",

                                  border:
                                    state.auth.theme == "dark"
                                      ? "1px solid " +
                                        themeStyleDark.primaryColor
                                      : "1px solid " + themeStyle.primaryColor,
                                }}
                                borderRadius={6}
                              >
                                <h3
                                  style={{
                                    marginTop: "17px",
                                    fontSize: "15px",
                                    color:
                                      state.auth.theme == "dark"
                                        ? themeStyleDark.primaryColor
                                        : themeStyle.primaryColor,
                                  }}
                                >
                                  EVERYONE
                                </h3>
                              </Button>
                            )}
                          </Grid>
                          <Grid item md={4}>
                            {selectedNoti == "EMPLOYEES" ? (
                              <Button
                                fullWidth
                                value="EMPLOYEES"
                                onClick={handleNotiSelect}
                                p={2}
                                style={{
                                  backgroundColor:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.primaryColor
                                      : themeStyle.primaryColor,
                                  color: "white",
                                  height: "35px",
                                  marginTop: "-15px",
                                }}
                                borderRadius={6}
                              >
                                <h3
                                  style={{
                                    marginTop: "17px",
                                    fontSize: "15px",
                                    letterSpacing: "1.5px",
                                  }}
                                >
                                  EMPLOYEES{" "}
                                </h3>
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                value="EMPLOYEES"
                                onClick={handleNotiSelect}
                                p={2}
                                style={{
                                  height: "35px",
                                  backgroundColor: themeStyle.backgroundColor,
                                  color: "white",
                                  marginTop: "-15px",
                                  border:
                                    state.auth.theme == "dark"
                                      ? "1px solid " +
                                        themeStyleDark.primaryColor
                                      : "1px solid " + themeStyle.primaryColor,
                                }}
                                borderRadius={6}
                              >
                                <h3
                                  style={{
                                    marginTop: "17px",
                                    fontSize: "15px",
                                    color:
                                      state.auth.theme == "dark"
                                        ? themeStyleDark.primaryColor
                                        : themeStyle.primaryColor,
                                  }}
                                >
                                  EMPLOYEES{" "}
                                </h3>
                              </Button>
                            )}
                          </Grid>
                          <Grid item md={4}>
                            {selectedNoti == "HR & MANAGER" ? (
                              <Button
                                fullWidth
                                value="HR & MANAGER"
                                onClick={handleNotiSelect}
                                p={2}
                                style={{
                                  backgroundColor:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.primaryColor
                                      : themeStyle.primaryColor,
                                  color: "white",
                                  height: "35px",
                                  marginTop: "-15px",
                                }}
                                borderRadius={6}
                              >
                                <h3
                                  style={{
                                    marginTop: "17px",
                                    fontSize: "15px",
                                    letterSpacing: "1.5px",
                                  }}
                                >
                                  HR & MANAGER{" "}
                                </h3>
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                value="HR & MANAGER"
                                onClick={handleNotiSelect}
                                p={2}
                                style={{
                                  height: "35px",
                                  backgroundColor: themeStyle.backgroundColor,
                                  color: "white",
                                  marginTop: "-15px",
                                  border:
                                    state.auth.theme == "dark"
                                      ? "1px solid " +
                                        themeStyleDark.primaryColor
                                      : "1px solid " + themeStyle.primaryColor,
                                }}
                                borderRadius={6}
                              >
                                <h3
                                  style={{
                                    marginTop: "17px",
                                    fontSize: "15px",
                                    color:
                                      state.auth.theme == "dark"
                                        ? themeStyleDark.primaryColor
                                        : themeStyle.primaryColor,
                                  }}
                                >
                                  HR & MANAGER{" "}
                                </h3>
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                        <br></br>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          placeholder="Subject"
                          variant="outlined"
                          value={subject}
                          size="small"
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <br></br> <br></br>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          placeholder="Message"
                          variant="outlined"
                          size="small"
                          style={{
                            marginBottom: "25px",
                          }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <br></br> <br></br>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            style={{
                              fontSize: "20px",
                              marginTop: "-5px",
                              height: "35px",
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                            onClick={() => sendNotification()}
                            variant="contained"
                            color="primary"
                          >
                            <span
                              style={{
                                color: "white",
                              }}
                            >
                              Send
                            </span>
                            <ChevronRightIcon
                              style={{
                                fontSize: "30px",
                                color: "white",
                                marginRight: "-10px",
                              }}
                            />
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div
                  style={{
                    padding: "100px 100px 90px 76px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.backgroundColor
                        : themeStyle.backgroundColor,
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {taskMinutes.length > 0 ? (
                    <Bar
                      options={options}
                      data={{
                        labels: taskLabels,
                        datasets: [
                          {
                            label: "Tasks",
                            data: taskMinutes,
                            backgroundColor: "rgba(53, 162, 235, 0.5)",
                          },
                        ],
                      }}
                    />
                  ) : (
                    <div>
                      <Lottie
                        style={{ height: "440px" }}
                        animationData={welcome}
                        loop={true}
                      />
                      <div style={{ fontSize: "36px", marginLeft: "50px" }}>
                        Welcome to Connectify
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      dispatch({
        type: "AUTH_ERROR",
      });
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
