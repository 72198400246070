import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { alertMessage } from "../actions/auth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { themeStyle, themeStyleDark } from "../components/Components";
import clip from "../img/clip.mp4";

export default function Logout() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  function getout() {
    dispatch(logout());
    return null;
  }

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          zIndex: 10,
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 1s",
          backgroundImage:
            "linear-gradient(to right, rgba(0,0,0,0.9),  rgba(0, 0, 0, 0.9))",
        }}
      >
        <video style={{ zIndex: 0 }} id="background-video" autoPlay loop muted>
          <source src={clip} type="video/mp4" />
        </video>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage:
              "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5))",
            height: "100%",
            flexDirection: "column",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: 2,
          }}
        >
          <div
            style={{
              padding: "50px",
              backgroundColor: themeStyle.backgroundColor,
              textAlign: "center",
              borderRadius: "6px",
            }}
          >
            <div style={{ marginTop: "-30px" }}>
              <h2>Do you want to Log out?</h2>
              <br></br>
              <Button
                style={{
                  marginRight: "15px",
                  height: "40px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                }}
                type="button"
                onClick={() => navigate("/")}
                variant="contained"
                color="primary"
              >
                <span
                  style={{
                    fontSize: "18px",
                    width: "150px",
                    height: "30px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                >
                  BACK TO HOME
                </span>{" "}
              </Button>
              <Button
                style={{
                  height: "40px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
                type="button"
                onClick={() => getout()}
                variant="contained"
                color="primary"
              >
                <span
                  style={{
                    fontSize: "18px",
                    width: "150px",
                    height: "30px",
                  }}
                >
                  Logout
                </span>{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
